<template>
  <div
    id="footer"
    class="mt-3"
  >
    <b-overlay
      :show="loading"
      rounded="sm"
      no-fade
      variant="transparent"
      opacity="0.97"
    >
      <b-card
        class="bg-transparent border-info shadow-lg"
        header-bg-variant="info"
        header-class="py-25"
        body-class="py-0 px-50"
      >
        <template #header>
          <span
            class="text-white font-weight-bolder m-0"
            :class="isMobileView ? 'font-medium-2' : 'font-medium-4'"
          >
            Hành trình đã chọn:
          </span>
        </template>

        <div
          v-if="!isEmpty(searchFlightArray)"
          class="py-50 px-0 rounded-lg my-75"
          style="background-color: #f5f5f5"
        >
          <div
            v-for="(flight, indexFlight) of searchFlightArray"
            :key="indexFlight"
            class="mb-75"
          >
            <div
              class="rounded-lg py-75 pl-2 fw-700 mb-50"
              style="background-color: #166987; color: white"
            >
              Hành trình #{{ indexFlight + 1 }}:
              <span class="fw-700">{{
                `${flight.startPoint}-${flight.endPoint}`
              }}</span>
            </div>

            <template v-if="selectedTrips[indexFlight]">
              <BRow
                v-for="(segment, indexSegment) in selectedTrips[indexFlight]"
                :key="indexSegment"
                class="mb-75 d-flex"
                no-gutters
              >
                <BCol
                  cols="12"
                  class="d-flex align-items-center"
                >
                  <span
                    :class="`mr-25 mr-md-50 font-weight-bolder
                    ${ ['xs', 'sm', 'md'].includes(appBreakPoint) ? 'font-small-4' : 'font-medium-1' }`"
                  >
                    {{ `${indexFlight + 1}*${indexSegment + 1}` }}
                  </span>

                  <b-form-input
                    v-if="segment"
                    :value="`${getSortTripBySegment(segment, true)}`"
                    :class="`text-body fw-700
                    ${ ['xs', 'sm', 'md'].includes(appBreakPoint) ? 'font-medium-1': 'font-medium-2' }`"
                    style="min-width: 300px"
                    disabled
                  />
                  <b-form-input
                    v-else
                    :value="`Chưa chọn chặng ${indexSegment + 1}`"
                    class="text-warning font-italic"
                    disabled
                  />
                </BCol>

                <BCol
                  v-if="['LL','DS'].includes(segment.bookingClass.status)"
                  cols="12"
                  class="text-danger fw-700 mt-75 ml-50"
                >
                  Tình trạng : Đặt sổ chờ {{ segment.bookingClass.status }}
                </BCol>
              </BRow>
            </template>

            <b-form-input
              v-else
              value="Chưa chọn chuyến bay"
              class="text-danger font-italic"
              disabled
            />
          </div>
        </div>

        <div class="py-1 d-flex-center">
          <b-button
            variant="outline-secondary"
            class="text-nowrap mr-1 mr-md-2"
            pill
            @click="handleBack"
          >
            {{ $t('flight.Back') }}
          </b-button>

          <b-button
            variant="gradient"
            pill
            class=""
            @click="handleSubmit(selectedTrips)"
          >
            {{ $t('flight.submit') }}
          </b-button>
        </div>
      </b-card>

      <b-card
        class="bg-transparent border-warning shadow-lg mt-1"
        header-bg-variant="warning"
        header-class="py-50"
        body-class="p-75"
      >
        <template #header>
          Booking
        </template>

        <b-form-textarea
          v-model="shortenText"
          class="font-weight-bolder text-uppercase overflow-auto"
          rows="10"
          max-rows="40"
          style="font-size: 16px; line-height: 24px; background-color:#ffffc0; color:#000; font-family:'Calibri', sans-serif;"
        />
      </b-card>
    </b-overlay>

    <ModalModifyCalcPriceClassBooking
      :data-trips="selectedTrips"
      :payload-search="payloadSearch"
      :booking-data="bookingData"
      :status-segment="statusSegment"
    />

    <ModalModifyConfirmAddFlightClassBooking
      v-if="selectedTripsResult"
      :selected-trips="selectedTripsResult"
      :booking-data="bookingData"
      :status-segment="statusSegment"
      :shorten-text.sync="shortenText"
    />
  </div>
</template>

<script>
import {
  BCard, BButton, BRow, BCol, BFormInput, BOverlay, BFormTextarea,
} from 'bootstrap-vue'
import {
  toRefs, ref, computed,
} from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'

import router from '@/router'

import { convertISODateTime, formatCurrency } from '@core/utils/filter'

import useReservationHandle from '@reservation/useReservationHandle'

import useToast from '@useToast'

export default {
  components: {
    BCard,
    BButton,
    BFormInput,
    BRow,
    BCol,
    BOverlay,
    BFormTextarea,

    ModalModifyCalcPriceClassBooking: () => import('./components/ModalModifyCalcPriceClassBooking.vue'),
    ModalModifyConfirmAddFlightClassBooking: () => import('./components/ModalModifyConfirmAddFlightClassBooking.vue'),
  },
  props: {
    arrSelectedTrips: {
      type: Array,
      default: () => [],
    },
    totalFare: {
      type: Number,
      default: () => 0,
    },
    payloadSearch: {
      type: Object,
      default: () => {},
    },
    flightsData: {
      type: Array,
      default: () => [],
    },
    bookingData: {
      type: Object,
      default: () => {},
    },
    searchFlightArray: {
      type: Array,
      default: () => [],
    },
    statusSegment: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const { toastError } = useToast()
    const { arrSelectedTrips: selectedTrips, payloadSearch } = toRefs(props)
    const { delay } = useReservationHandle()

    const priceCalc = ref(null)
    const loading = ref(false)
    const isConfirm = ref(false)
    const shortenText = ref(null)
    const searchPassenger = computed(() => {
      const result = {
        ADT: 0,
        CNN: 0,
        INF: 0,
      }
      if (!isEmpty(payloadSearch.value)) {
        const data = payloadSearch.value
        result.ADT = data.adult
        result.CNN = data.child
        result.INF = data.infant
      }
      return result
    })

    const totalFareCalc = computed(() => {
      let total = 0
      if (priceCalc.value) {
        total = priceCalc.value.reduce((total, item) => {
          if (searchPassenger.value[item.paxType] > 0) {
            return total + item.fare * searchPassenger.value[item.paxType]
          }
          return total
        }, 0)
      }
      return total
    })

    function handleCalcPrice(tripData) {
      if (tripData.some(item => item === null)) {
        toastError({
          title: 'Vui lòng chọn các hành trình!',
        })
      } else if (tripData && tripData[0][0]) {
        this.$bvModal.show(
          'modal-modify-result-recalculate-price-class-booking',
        )
      } else {
        toastError({
          title: 'messagesList.error',
          content: 'Lỗi gửi thông tin hành trình.',
        })
      }
    }

    function handleBack() {
      router.go(-1)
    }

    const selectedTripsResult = ref(null)

    async function handleSubmit(trips) {
      selectedTripsResult.value = null

      if (trips.some(item => item === null)) {
        toastError({
          title: 'Vui lòng chọn các hành trình!',
        })
      } else {
        await new Promise(resolve => {
          selectedTripsResult.value = trips
          resolve()
        })

        await delay(250)

        this.$bvModal.show('modal-modify-class-booking-add-flight-confirm')
      }
    }

    function handleHideModal() {
      isConfirm.value = false
    }

    function getSortTripBySegment(segment, isSelect = false) {
      const {
        departure,
        arrival,
        departureDate,
        departureTimezone,
        arrivalDate,
        arrivalTimezone,
        airline,
        flightNumber,
        airCraft,
      } = segment

      const getDepartureTime = departureDate && departureTimezone
        ? convertISODateTime(departureDate, departureTimezone).time
        : ''
      const getArrivalTime = arrivalDate && arrivalTimezone
        ? convertISODateTime(arrivalDate, arrivalTimezone).time
        : ''
      const getDepartureDayAndMonth = departureDate && departureTimezone
        ? convertISODateTime(departureDate, departureTimezone).dayAndMonth
        : ''

      const getAirline = airline || ''
      const getFlightNumber = flightNumber || ''
      const getDeparture = departure || ''
      const getArrival = arrival || ''
      const getAirCraft = airCraft || ''
      const getStatus = segment?.bookingClass?.status || ''

      return isSelect // dùng với key value-input: trong div chưa tăng được space
        ? `${convertISODateTime(segment.departureDate, segment.departureTimezone).time}-${convertISODateTime(segment.arrivalDate, segment.arrivalTimezone).time}  ${convertISODateTime(segment.departureDate, segment.departureTimezone).dayAndMonth}  ${segment.airline}${segment.flightNumber}  ${segment.bookingClass.code}  ${segment.departure}${segment.arrival} ${getStatus}`
        : `${getDepartureTime}-${getArrivalTime}   ${getDepartureDayAndMonth}   ${getAirline}${getFlightNumber}   ${getDeparture}${getArrival}  |  ${getAirCraft}`
    }

    return {
      selectedTrips,
      loading,
      isEmpty,
      priceCalc,
      isConfirm,
      selectedTripsResult,
      totalFareCalc,
      searchPassenger,

      shortenText,

      convertISODateTime,
      handleSubmit,
      handleBack,
      formatCurrency,
      handleHideModal,
      handleCalcPrice,

      getSortTripBySegment,
    }
  },
}
</script>

<style lang="scss" scoped>
.margin-checkbox-label::v-deep {
  .custom-control-label {
    margin-top: 2px !important;
  }
}

.select_custom {
  ::v-deep .vs__dropdown-toggle {
    min-height: 37.98px !important;
    padding: 0px !important;

    .vs__search {
      position: absolute;
      border: 0 !important;
      width: 0px !important;
      padding: 0px !important;
    }
  }
}
</style>
