var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt-3",
    attrs: {
      "id": "footer"
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loading,
      "rounded": "sm",
      "no-fade": "",
      "variant": "transparent",
      "opacity": "0.97"
    }
  }, [_c('b-card', {
    staticClass: "bg-transparent border-info shadow-lg",
    attrs: {
      "header-bg-variant": "info",
      "header-class": "py-25",
      "body-class": "py-0 px-50"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('span', {
          staticClass: "text-white font-weight-bolder m-0",
          class: _vm.isMobileView ? 'font-medium-2' : 'font-medium-4'
        }, [_vm._v(" Hành trình đã chọn: ")])];
      },
      proxy: true
    }])
  }, [!_vm.isEmpty(_vm.searchFlightArray) ? _c('div', {
    staticClass: "py-50 px-0 rounded-lg my-75",
    staticStyle: {
      "background-color": "#f5f5f5"
    }
  }, _vm._l(_vm.searchFlightArray, function (flight, indexFlight) {
    return _c('div', {
      key: indexFlight,
      staticClass: "mb-75"
    }, [_c('div', {
      staticClass: "rounded-lg py-75 pl-2 fw-700 mb-50",
      staticStyle: {
        "background-color": "#166987",
        "color": "white"
      }
    }, [_vm._v(" Hành trình #" + _vm._s(indexFlight + 1) + ": "), _c('span', {
      staticClass: "fw-700"
    }, [_vm._v(_vm._s("".concat(flight.startPoint, "-").concat(flight.endPoint)))])]), _vm.selectedTrips[indexFlight] ? _vm._l(_vm.selectedTrips[indexFlight], function (segment, indexSegment) {
      return _c('BRow', {
        key: indexSegment,
        staticClass: "mb-75 d-flex",
        attrs: {
          "no-gutters": ""
        }
      }, [_c('BCol', {
        staticClass: "d-flex align-items-center",
        attrs: {
          "cols": "12"
        }
      }, [_c('span', {
        class: "mr-25 mr-md-50 font-weight-bolder\n                  ".concat(['xs', 'sm', 'md'].includes(_vm.appBreakPoint) ? 'font-small-4' : 'font-medium-1')
      }, [_vm._v(" " + _vm._s("".concat(indexFlight + 1, "*").concat(indexSegment + 1)) + " ")]), segment ? _c('b-form-input', {
        class: "text-body fw-700\n                  ".concat(['xs', 'sm', 'md'].includes(_vm.appBreakPoint) ? 'font-medium-1' : 'font-medium-2'),
        staticStyle: {
          "min-width": "300px"
        },
        attrs: {
          "value": "".concat(_vm.getSortTripBySegment(segment, true)),
          "disabled": ""
        }
      }) : _c('b-form-input', {
        staticClass: "text-warning font-italic",
        attrs: {
          "value": "Ch\u01B0a ch\u1ECDn ch\u1EB7ng ".concat(indexSegment + 1),
          "disabled": ""
        }
      })], 1), ['LL', 'DS'].includes(segment.bookingClass.status) ? _c('BCol', {
        staticClass: "text-danger fw-700 mt-75 ml-50",
        attrs: {
          "cols": "12"
        }
      }, [_vm._v(" Tình trạng : Đặt sổ chờ " + _vm._s(segment.bookingClass.status) + " ")]) : _vm._e()], 1);
    }) : _c('b-form-input', {
      staticClass: "text-danger font-italic",
      attrs: {
        "value": "Chưa chọn chuyến bay",
        "disabled": ""
      }
    })], 2);
  }), 0) : _vm._e(), _c('div', {
    staticClass: "py-1 d-flex-center"
  }, [_c('b-button', {
    staticClass: "text-nowrap mr-1 mr-md-2",
    attrs: {
      "variant": "outline-secondary",
      "pill": ""
    },
    on: {
      "click": _vm.handleBack
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.Back')) + " ")]), _c('b-button', {
    attrs: {
      "variant": "gradient",
      "pill": ""
    },
    on: {
      "click": function click($event) {
        return _vm.handleSubmit(_vm.selectedTrips);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.submit')) + " ")])], 1)]), _c('b-card', {
    staticClass: "bg-transparent border-warning shadow-lg mt-1",
    attrs: {
      "header-bg-variant": "warning",
      "header-class": "py-50",
      "body-class": "p-75"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_vm._v(" Booking ")];
      },
      proxy: true
    }])
  }, [_c('b-form-textarea', {
    staticClass: "font-weight-bolder text-uppercase overflow-auto",
    staticStyle: {
      "font-size": "16px",
      "line-height": "24px",
      "background-color": "#ffffc0",
      "color": "#000",
      "font-family": "'Calibri', sans-serif"
    },
    attrs: {
      "rows": "10",
      "max-rows": "40"
    },
    model: {
      value: _vm.shortenText,
      callback: function callback($$v) {
        _vm.shortenText = $$v;
      },
      expression: "shortenText"
    }
  })], 1)], 1), _c('ModalModifyCalcPriceClassBooking', {
    attrs: {
      "data-trips": _vm.selectedTrips,
      "payload-search": _vm.payloadSearch,
      "booking-data": _vm.bookingData,
      "status-segment": _vm.statusSegment
    }
  }), _vm.selectedTripsResult ? _c('ModalModifyConfirmAddFlightClassBooking', {
    attrs: {
      "selected-trips": _vm.selectedTripsResult,
      "booking-data": _vm.bookingData,
      "status-segment": _vm.statusSegment,
      "shorten-text": _vm.shortenText
    },
    on: {
      "update:shortenText": function updateShortenText($event) {
        _vm.shortenText = $event;
      },
      "update:shorten-text": function updateShortenText($event) {
        _vm.shortenText = $event;
      }
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }